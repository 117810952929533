import LineChartSetup from "./LineChartSetup";
import ProjectInformation from "./ProjectInformation";

export const InfoAndChartWrapper = ({ projectData, chartData }) => {
  if (!projectData || !chartData) {
    return (
      <div className="w-full h-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
        <div className="w-full flex items-center justify-center flex-grow">
          <div className="flex items-center flex-col">
            <div className="text-white/70 text-sm">
              "Error fetching Data... "
            </div>
          </div>
        </div>
      </div>
    );
  }
 return (<div className="w-full flex flex-row gap-x-2.5">
    <ProjectInformation projectData={projectData} />
    <LineChartSetup chartData={chartData} />
  </div>)
};
