import { useEffect } from "react";
import { useParams } from "react-router-dom";
import AuthenticatedContainer from "../components/containers/AuthenticatedContainer";
import { FarmHistoryWrapper } from "../components/containers/FarmHistory/FarmHistoryWrapper";
import { useSetPoints } from "../hooks/use-set-points";
import { points } from "../utils/pointsActions";
export default function BestFarmHistory() {
  const params = useParams();
    const { setPoints } = useSetPoints();
  
    useEffect(() => {
      const addPoints = async (pointsAction) => {
        await setPoints(pointsAction);
      }
      addPoints(points.single_bestfarms).catch(console.error);
    })
  return (
    <main className="w-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
      <AuthenticatedContainer>
        <FarmHistoryWrapper farmId={params?.farmId} />
      </AuthenticatedContainer>
    </main>
  );
}
