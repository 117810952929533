import ReactDropdown from "react-dropdown";
import "react-dropdown/style.css";
import { GenericInput } from "../../GenericInput";

const ActionButton = ({ label, action, isSelected }) => {
  return (
    <button
      className={`border border-[#d22326] rounded-sm py-1 px-2 text-xs hover:bg-[#d22326] ${
        isSelected ? "bg-[#d22326] text-white" : "text-white"
      }`}
      onClick={action}
    >
      {label}
    </button>
  );
};

const TVLOptions = [
  {
    label: "MICRO (0 TO 100K)",
    id: 0,
  },
  {
    label: "SMALL (100K TO 1M)",
    id: 1,
  },
  {
    label: "MEDIUM (1M TO 5M)",
    id: 2,
  },
  {
    label: "BIG (5M TO 20M)",
    id: 3,
  },
  {
    label: "GIANT (20M+)",
    id: 4,
  },
];
const apyBase1dOptions = [
  {
    label: "MICRO (0 TO 5%)",
    id: 0,
  },
  {
    label: "SMALL (5% TO 20%)",
    id: 1,
  },
  {
    label: "MEDIUM (20% TO 100%)",
    id: 2,
  },
  {
    label: "HIGH (100% TO 500%)",
    id: 3,
  },
  {
    label: "CRAZY (500%+)",
    id: 4,
  },
];
const apyBase30dOptions = [
  {
    label: "MICRO (0 TO 5%)",
    id: 0,
  },
  {
    label: "SMALL (5% TO 20%)",
    id: 1,
  },
  {
    label: "MEDIUM (20% TO 100%)",
    id: 2,
  },
  {
    label: "HIGH (100% TO 500%)",
    id: 3,
  },
  {
    label: "CRAZY (500%+)",
    id: 4,
  },
];
export const TableFilters = ({
  setTvlUsdFilter,
  onlyStable,
  setOnlyStable,
  chains,
  projects,
  selectedProject,
  setSelectedProject,
  selectedChain,
  setSelectedChain,
  tvlUsdFilter,
  apy1dFilter,
  apy30dFilter,
  setApy1dFilter,
  setApy30dFilter,
  symbolName = "",
  setSymbolName,
}) => {
  /* const [hoverApy, setHoverApy] = useState(false); */

  return (
    <div className="w-full mb-2 border border-neutral-300/20 rounded-md p-2">
      <div className="font-semibold w-full border-b border-b-neutral-300/20 mb-2">
        Filters
      </div>
      {/*  <div className="flex flex-row items-center w-full border-b border-b-neutral-300/20 justify-between pb-2 mb-2">
        <div className="whitespace-nowrap text-sm font-semibold">Apy</div>
        {defaultApyValues && (
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            defaultValue={[defaultApyValues[0], defaultApyValues[1]]}
            min={defaultApyValues[0]}
            max={defaultApyValues[1]}
            ariaLabel={["Lower thumb", "Upper thumb"]}
            ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
            renderThumb={(props, state) => (
              <div
                {...props}
                onMouseEnter={() => setHoverApy(true)}
                onMouseLeave={() => setHoverApy(false)}
              >
                <div
                  className={`absolute -top-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                    hoverApy ? "flex" : "hidden"
                  } py-1 px-2 bg-ui-black border border-white/70 rounded-md`}
                >
                  {state.valueNow === 1000 ? "MAX" : state.valueNow}
                </div>
              </div>
            )}
            onAfterChange={(value) => setApyFilter([value[0], value[1]])}
            pearling
            minDistance={1}
          />
        )}
      </div> */}
      {/** TVL filter */}
      <div className="flex flex-row items-center w-full border-b border-b-neutral-300/20 justify-between pb-2 mb-2">
        <div className="whitespace-nowrap text-sm font-semibold">TVL $</div>
        <div className="flex flex-row gap-x-1">
          {TVLOptions.map((option) => (
            <ActionButton
              key={option.id}
              label={option.label}
              action={() => setTvlUsdFilter(option.id)}
              isSelected={tvlUsdFilter.includes(option.id)}
            />
          ))}
        </div>
      </div>
      {/** apybase1d filter */}
      <div className="flex flex-row items-center w-full border-b border-b-neutral-300/20 justify-between pb-2 mb-2">
        <div className="whitespace-nowrap text-sm font-semibold">APY daily</div>
        <div className="flex flex-row gap-x-1">
          {apyBase1dOptions.map((option) => (
            <ActionButton
              key={"apyBase1dOptions" + option.id}
              label={option.label}
              action={() => setApy1dFilter(option.id)}
              isSelected={apy1dFilter.includes(option.id)}
            />
          ))}
        </div>
      </div>
      {/** apybase30d filter */}
      <div className="flex flex-row items-center w-full border-b border-b-neutral-300/20 justify-between pb-2 mb-2">
        <div className="whitespace-nowrap text-sm font-semibold">
          APY monthly
        </div>
        <div className="flex flex-row gap-x-1">
          {apyBase30dOptions.map((option) => (
            <ActionButton
              key={"apyBase30dOptions" + option.id}
              label={option.label}
              action={() => setApy30dFilter(option.id)}
              isSelected={apy30dFilter.includes(option.id)}
            />
          ))}
        </div>
      </div>
      <div className="w-full grid grid-cols-4 gap-x-4 pb-2 ">
        <div class="flex items-center justify-center">
          <div className="whitespace-nowrap text-sm font-semibold mr-2">
            Only stable
          </div>
          <div class="inline-flex items-center">
            <label class="flex items-center cursor-pointer relative">
              <input
                type="checkbox"
                checked={onlyStable}
                onClick={() => {
                  setOnlyStable(!onlyStable);
                }}
                class="peer h-4 w-4 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-[#d22326] checked:border-[#d22326]"
                id="check2"
              />
              <span class="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </span>
            </label>
          </div>
        </div>
        {/* <div className="grow grid grid-cols-3 gap-x-2 "> */}
          <ReactDropdown
            options={chains}
            onChange={(val) => setSelectedChain(val.value)}
            value={selectedChain}
            placeholder="Select chain"
            className="w-fulll border border-neutral-300/20 rounded-md bg-transparen"
            controlClassName="!bg-transparent !rounded-md !p-1.5"
            placeholderClassName="text-[#d22326] text-sm"
            menuClassName="!bg-neutral-900 !text-white !text-sm !rounded-md"
            dropdownOptionClassName="text-red-500"
          />
          <ReactDropdown
            options={projects}
            onChange={(val) => setSelectedProject(val.value)}
            value={selectedProject}
            placeholder="Select chain"
            className="w-full border border-neutral-300/20 rounded-md bg-transparen"
            controlClassName="!bg-transparent !rounded-md !p-1.5"
            placeholderClassName="text-[#d22326] text-sm"
            menuClassName="!bg-neutral-900 !text-white !text-sm !rounded-md"
            dropdownOptionClassName="text-red-500"
          />
          <div className="w-full ">
            <GenericInput
              label={"Symbol name"}
              action={setSymbolName}
              value={symbolName}
              active={symbolName?.length > 0}
              thin
            />
          </div>
        {/* </div> */}
      </div>
    </div>
  );
};
