import axios from "axios";
import { useEffect, useState } from "react";

const isDev = true;

export const useGetBlurLoans = () => {
  const [blurLoans, setBlurLoans] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://${isDev ? 'dev' : 'api'}.bc.army/api/latest_blurloans_data`)
      .then(function (response) {
        // handle success;
        setBlurLoans(response.data.Data);
        if(response.data.last_updated){
          setLastUpdated(response.data.last_updated);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, []);
  return { blurLoans, loading, lastUpdated };
};
