import { useEffect, useState } from "react";
import axios from "axios";
const isDev =
Boolean(process.env.REACT_APP_IS_DEV_MODE) || false;
export const useGetDuneData = () => {
  const [dexData, setDexData] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(dexData) return
    setLoading(true);
    axios
      .get(`https://${isDev ? 'dev' : 'api'}.bc.army/api/latest_dune_data`)
      .then(function (response) {
        // handle success
        setDexData(response.data.Data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, [dexData]);
  return { dexData, loading };
};
