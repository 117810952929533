import React from "react";
import { Line } from "react-chartjs-2";

const LineChartSetup = ({ chartData }) => {
  return (
    <div className="w-2/3">
      <div
        style={{
          width: "100%",
          height: "500px",
          margin: "0 auto",
        }}
        className="w-full border border-neutral-300/20  rounded-sm p-5 h-[60vh] relative z-20"
      >
        <img
          src="/SVG/pic-nero.svg"
          alt="bc logo"
          className="pointer-events-none h-[70%] w-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10 z-10"
        />
        <Line
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "top",
              },
            },
            scales: {
              x: {
                ticks: {
                  callback: function (value) {
                    return this.getLabelForValue(value); // Ensures the labels are displayed properly
                  },
                  maxRotation: 45, // Maximum rotation angle
                  minRotation: 45, // Minimum rotation angle
                  stepSize: 2,
                },
              },
              y: {
                position: "right",

                title: {
                  display: true,
                  text: "APY (%)",
                },
                grid: {
                  color: "rgba(200, 200, 200, 0.5)", // Adjust the color of horizontal lines
                  lineWidth: 1, // Thickness of the grid lines
                  drawTicks: true, // Ensures gridlines appear at tick points
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default LineChartSetup;
