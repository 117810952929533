import { useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
const FarmCard = ({ infos, i, borderRight }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (!infos.symbol) return;
    navigate(
      `/best-farms/history?s=${infos.symbol}&p=${infos.project}&c=${infos.chain}&pm=${infos.poolMeta}`,
      {
        state: {
          symbol: infos.symbol,
          project: infos.project,
          poolMeta: infos.poolMeta,
        },
      }
    );
  };
  /*   const handleClick = () => {
    navigate(`/pe-analysis/${infos.gecko_id}`);
  }; */

  return (
    <div
      className={`w-full font-mono text-sm cursor-pointer ${
        borderRight && "md:border-r md:border-r-neutral-300/20"
      } ${i < 3 && "md:border-r md:border-r-neutral-300/20"} ${
        i % 2 === 0 && "max-md:border-r max-md:border-r-neutral-300/20"
      }  ${i > 1 && "max-md:border-t max-md:border-t-neutral-300/20"}`}
      onClick={() => handleClick()}
    >
      <div className="p-2">
        <div className="flex flex-row justify-between">
          <div className="text-xs">Project</div>{" "}
          <div className="font-semibold">{infos.project}</div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="text-xs">Symbol</div>{" "}
          <div className="font-semibold">{infos.symbol}</div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="text-xs">TVL USD</div>{" "}
          <div className="font-semibold">${infos.tvlUsd.toLocaleString()}</div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="text-xs">APY 30D</div>{" "}
          <div className="font-semibold">{infos.apyMean30d.toFixed(2)}%</div>
        </div>
        {infos.feedback && (
          <div className="flex flex-col  text-xs mt-2">
            <div>{infos.feedback}</div>
          </div>
        )}
      </div>
    </div>
  );
};
export const HighlightedFarms = ({ data }) => {
  if (data.length < 5)
    return (
      <div className="w-full mt-6 mb-2.5">
        <div className="text-md mb-1">On our watchlist</div>
        <div className="grid grid-cols-2 md:grid-cols-4 rounded-md border border-neutral-300/20">
          {data.map((infos, i) => (
            <FarmCard
              infos={infos}
              key={infos.project + infos.symbol + i}
              i={i}
            />
          ))}
        </div>
      </div>
    );
  return (
    <div className="w-full mt-6 mb-2.5">
      <div className="text-md mb-1">On our watchlist</div>
      <div className=" w-full  rounded-md border border-neutral-300/20">
        <Swiper
          slidesPerView={4}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          className="w-full"
          loop
          modules={[Autoplay]}
          autoplay={{
            delay: 4000,
          }}
        >
          {data.map((infos, i) => (
            <SwiperSlide i={i}>
              <FarmCard
                infos={infos}
                key={infos.project + infos.symbol + i}
                borderRight={true}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
