import { useEffect, useState } from "react";
import axios from "axios";
const isDev =
Boolean(process.env.REACT_APP_IS_DEV_MODE) || false;
export const useGetLeaderboard = () => {
  const [points, setPoints] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`https://${isDev ? 'dev' : 'api'}.bc.army/api/points_leaderboard`)
      .then(function (response) {
        // handle success
        if(response.data.wallets.length >0) {
          setPoints(response.data.wallets);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  }, []);
  return { points, isLoading };
};
