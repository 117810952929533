import { useEffect, useState } from "react";
import BcLogo from "./BCLogo";
import WalletAndAuth from "./WalletAndAuth";
import axios from "axios";
import { Points } from "./Points";

const Header = () => {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const [gasData, setGasData] = useState(null);

  useEffect(() => {
    const savedGasData = localStorage.getItem("gas-data");

    const fetchDataAndStore = async () => {
      try {
        const response = await axios
          .get("https://bcarmy-temp.vercel.app/api/get-gas")
          .then(function (response) {
            const res = response.data;
            return res;
          })
          .catch(function (error) {
            console.log(error);
          });
        const dataToStore = response;
        const expirationTime = new Date();
        expirationTime.setHours(expirationTime.getMinutes() + 30);
        localStorage.setItem(
          "gas-data",
          JSON.stringify({
            data: dataToStore,
            expiration: expirationTime.getTime(),
          })
        );
        setGasData(response);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    if (!savedGasData) {
      setLoading(true);
      fetchDataAndStore();
    } else if (savedGasData && !JSON.parse(savedGasData).data) {
      localStorage.removeItem("gas-data");
      setLoading(true);
      fetchDataAndStore();
    } else {
      const currentTime = new Date().getTime();
      const savedExpirationTime = JSON.parse(savedGasData).expiration;
      if (currentTime > savedExpirationTime) {
        localStorage.removeItem("gas-data");
        setLoading(true);
        fetchDataAndStore();
      } else {
        setGasData(JSON.parse(savedGasData).data);
        setLoading(false);
      }
    }
  }, []);
  return (
    <div className="w-screen h-16 bg-ui-dark-gray pl-5 pr-8 border-b border-b-neutral-300/20 fixed top-0 flex flex-row items-center justify-between z-50">
      <a href="https://token.bc.army/" target="_blank" rel="noreferrer" className="flex flex-row items-center gap-x-2">
        <img src="/SVG/pic-bianco.svg" alt="bc logo" className="h-[40px]"/>
        <img src="/SVG/log-color.svg" alt="bc logo" className="w-[100px]"/>

      </a>
      <div className="flex flex-row items-center gap-x-2.5 max-md:ml-auto max-md:pr-2">
        <div className="hidden md:flex flex-row items-center gap-x-1.5 border border-neutral-300/20 rounded-full pr-2 pl-4 py-1 text-xs generic-shadow transition-all duration-200 ease-in">
          <div className="text-sm font-semibold">Trade with Banana 🍌</div>
          <a href="https://t.me/BananaGunSolana_bot?start=ref_x9953">
            <img
              src="/sol.png"
              alt="sol logo"
              className="h-7 w-auto object-contain hover:scale-110 transition-all duration-200"
            />
          </a>
          <a href="https://t.me/BananaGunSniper_bot?start=ref_8b5app">
            <img
              src="/eth.png"
              alt="eth logo"
              className="rounded-full h-7 w-auto object-contain hover:scale-110 transition-all duration-200"
            />
          </a>
        </div>
        {gasData && (
          <div className="hidden md:flex flex-row gap-x-1.5 items-center">
            <div className="h-[38px] flex items-center text-sm font-light px-4 border border-neutral-300/20 rounded-full py-1 generic-shadow transition-all duration-200 ease-in">
              HOT ON ETH 🔥{" "}
              <a
                href={gasData.hot.url}
                target="_blank"
                className="font-bold ml-2"
                rel="noreferrer"
              >
                {gasData?.hot?.name}
              </a>
            </div>
            <div className="h-[38px] flex items-center gap-x-1 text-sm font-light px-4 border border-neutral-300/20 rounded-full py-1 generic-shadow transition-all duration-200 ease-in">
              <span>ETH Gas:</span>
              <span className="font-bold">
                {gasData?.gas ? Number(gasData?.gas).toFixed(3) + " Gwei" : ""}
              </span>
            </div>
          </div>
        )}
        <Points />
        <WalletAndAuth />
      </div>
    </div>
  );
};
export default Header;
