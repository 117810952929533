import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "../../Loader";
import DuneChart from "./DuneChart";
import { BcSign } from "../../Sign";

export const Dune = () => {
  const [loading, setLoading] = useState(true);
  const [duneData, setDuneData] = useState(null);
  useEffect(() => {
    const savedDuneData = localStorage.getItem("dune-data");

    const fetchDataAndStore = async () => {
      try {
        const response = await axios
          .get(
            "https://api.dune.com/api/v1/endpoints/mcccsm/narative-last7-days/results?limit=1000&api_key=oOnMMISbD7W9YPTOYWe2w7t1eVnqEnvn"
          )
          .then(function (response) {
            return response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
        const dataToStore = response;
        const expirationTime = new Date();
        expirationTime.setHours(expirationTime.getHours() + 3);
        localStorage.setItem(
          "dune-data",
          JSON.stringify({
            data: dataToStore,
            expiration: expirationTime.getTime(),
          })
        );
        setDuneData(response);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    if (!savedDuneData) {
      setLoading(true);
      fetchDataAndStore();
    } else if (savedDuneData && !JSON.parse(savedDuneData).data) {
      localStorage.removeItem("dune-data");
      setLoading(true);
      fetchDataAndStore();
    } else {
      const currentTime = new Date().getTime();
      const savedExpirationTime = JSON.parse(savedDuneData).expiration;
      if (currentTime > savedExpirationTime) {
        localStorage.removeItem("dune-data");
        setLoading(true);
        fetchDataAndStore();
      } else {
        setDuneData(JSON.parse(savedDuneData).data);
        setLoading(false);
      }
    }
  }, []);
  return (
    <div className="w-full px-5 h-[50vh] md:h-[calc(100vh-160px)] 2xl:col-span-2 2xl:h-[calc(60vh-160px)] 2xl:min-h-[300px] 2xl:mt-10">
      <div className="oswald text-xl uppercase">
        Last 7 Days Perf Comparative
      </div>
      <div className="oswald text-sm 2xl:text-xs pb-2.5">
        Cryptos performance by sector
      </div>
      <div className="w-full h-[calc(100%-40px)] mt-2.5 rounded-sm max-md:max-w-full bg-contain bg-center bg-no-repeat relative">
        <BcSign className={" right-3"} />
        <img
          src="/SVG/pic-nero.svg"
          alt="bc logo"
          className=" pointer-events-none h-[50%] md:h-[60%] w-auto absolute left-1/2 top-1/3 md:top-[40%] -translate-x-1/2 -translate-y-1/2 invert opacity-10"
        />

        {!loading && duneData ? (
          <div className="w-full  h-full">
            <DuneChart data={duneData.result.rows} />
          </div>
        ) : (
          <div className="w-full h-64 flex items-center justify-center">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};
