import { useEffect, useState } from "react";
import axios from "axios";
const isDev =
Boolean(process.env.REACT_APP_IS_DEV_MODE) || false;
export const useGetAggregatedData = () => {
  const [homeData, setHomeData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://${isDev ? 'dev' : 'api'}.bc.army/api/fetch_aggregated_data`)
      .then(function (response) {
        // handle success
        setHomeData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, []);
  return { homeData, loading };
};
