import { useEffect, useState } from "react";
import axios from "axios";
const isDev =
Boolean(process.env.REACT_APP_IS_DEV_MODE) || false;
export const useGetHighlightedFarms = () => {
  const [highlightedFarms, setHighlightedFarms] = useState(null);

  const [loadingHighlightedFarms, setLoadingHighlightedFarms] = useState(false);

  useEffect(() => {
    setLoadingHighlightedFarms(true);
    axios
      .get(`https://${isDev ? 'dev' : 'api'}.bc.army/api/highlighted_farms`)
      .then(function (response) {
        // handle success
        setHighlightedFarms(response.data);
        setLoadingHighlightedFarms(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoadingHighlightedFarms(false);
      });
  }, []);
  return { highlightedFarms, loadingHighlightedFarms };
};
