import { useMemo } from "react";
import { useGetTokenData } from "../../../hooks/use-get-token-data";
import { useNavigate } from "react-router-dom";
import { useGetPeData } from "../../../hooks/use-get-pe-data";
import Loader from "../../Loader";
import PeSingleElementCharts from "../PE/PeSingleElementCharts";

export const PeTokenWrapper = ({ tokenId }) => {
  const navigate = useNavigate();
  const { peData, loading } = useGetPeData();
  const {
    isLoading,
    isLoadingTexts,
    tokenData,
    holdersRevText,
    projectRevText,
    graphInfo,
    lastUpdated
  } = useGetTokenData(tokenId);
  const handleGoBack = () => {
    navigate("/pe-analysis");
  };

  const selectedTokenInfos = useMemo(() => {
    if (!peData) return null;
    return peData.find((e) => e.id === tokenId);
  }, [peData, tokenId]);

  if (tokenId === "-" || !tokenId) {
    return (
      <main className="w-full relative h-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
        <div className="w-full flex items-center justify-center flex-grow">
          <div className="flex items-center flex-col">
            <img
              src="/SVG/pic-nero.svg"
              alt="bc logo"
              className="pointer-events-none h-[70%] aspect-square absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10 "
            />
            <div className="text-2xl ">This token seems to be broken.</div>
            <div className="text-lg text-center">
              (Or, more likely, our devs broke something...)
            </div>
            <a href="/pe-analysis" className="text-xl text-red-700">
              {" "}
             Go back to the PE Analysis Page
            </a>
          </div>
        </div>
      </main>
    );
  }
  if (loading || isLoading || isLoadingTexts) {
    return (
      <main className="w-full h-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
        <div className="w-full flex items-center justify-center flex-grow">
          <div className="flex items-center flex-col">
            <Loader />
            <div className="text-white/70 text-sm">Loading Data...</div>
          </div>
        </div>
      </main>
    );
  }
  return (
    <PeSingleElementCharts
      goBack={() => handleGoBack()}
      id={tokenId}
      extraInfos={selectedTokenInfos}
      tokenData={tokenData}
      holdersRevText={holdersRevText}
      projectRevText={projectRevText}
      graphInfo={graphInfo}
      lastUpdated={lastUpdated}
    />
  );
};
