import Loader from "../../Loader";
const ProjectKpi = ({ label, value }) => {
  return (
    <div className="w-full py-2.5 2xl:py-1 flex flex-col items-center justify-center bg-ui-black rounded-md">
      <div className="text-white/50 mb-1 uppercase text-sm 2xl:text-[8px] text-center whitespace-nowrap">
        {label}
      </div>
      <div className="text-white font-bold text-lg 2xl:text-xs">{value}</div>
    </div>
  );
};
export const ProjectOfTheDay = ({ data, loading }) => {
  return (
    <div className="w-full md:h-[calc(100vh-160px)] 2xl:h-[calc(60vh-160px)] 2xl:min-h-[450px] px-5 flex flex-col max-2xl:mt-5">
      <div className="oswald text-xl ">PROJECT OF THE WEEK - {data?.projectOfTheWeek?.project_title || 'BANANA'}</div>
      <div className="oswald text-sm 2xl:text-xs pb-2.5">
        Handpicked project by our analysts (best R/R){" "}
      </div>
      {!loading && data?.projectOfTheWeek ? (
        <div className="flex flex-col md:flex-row 2xl:flex-col gap-x-5  md:h-[calc(100%-40px)] ">
          <iframe
            className="grow h-[50vh] max-md:w-full 2xl:w-full 2xl:h-[70%] rounded-sm"
            id="dextools-widget"
            title="DEXTools Trading Chart"
            width="500"
            height="400"
            src={data.projectOfTheWeek.iframe_url}
          ></iframe>
          <div className="w-full md:w-1/4 h-auto 2xl:w-full 2xl:h-[30%]">
            <div className="w-full bg-black rounded-sm h-fit grid grid-cols-2 2xl:grid-cols-8 p-2.5 gap-2.5">
              <ProjectKpi
                label="Market cap"
                value={
                  "$" +
                  (data.projectOfTheWeek.mktcap / 1_000_000).toFixed(2) +
                  "M"
                }
              />
              <ProjectKpi
                label="Liquidity"
                value={
                  "$" +
                  (data.projectOfTheWeek.liquidity / 1_000_000).toFixed(2) +
                  "M"
                }
              />
              <ProjectKpi
                label="Circ Supply"
                value={data.projectOfTheWeek.circulatingSupply / 1_000 + "K"}
              />
              <ProjectKpi
                label="Holders"
                value={data.projectOfTheWeek.holders / 1_000 + "K"}
              />
              <ProjectKpi
                label="Total MKCAP"
                value={
                  "$" +
                  (data.projectOfTheWeek.totalMktcap / 1_000_000).toFixed(2) +
                  "M"
                }
              />
              <ProjectKpi
                label="24H Volume"
                value={
                  "$" +
                  (data.projectOfTheWeek.volume24h / 1_000_000).toFixed(2) +
                  "M"
                }
              />
              <ProjectKpi
                label="Total Supply"
                value={
                  (data.projectOfTheWeek.totalSupply / 1_000_000).toFixed(2) +
                  "M"
                }
              />
              <div className="w-full py-2.5 2xl:py-1  flex flex-col items-center justify-center bg-ui-black rounded-md">
                <div className="text-sm 2xl:text-[8px] text-white/50 mb-1 uppercase text-center whitespace-nowrap">
                  % CIRC. SUPPLY
                </div>
                <div className="flex flex-row 2xl:flex-col items-center w-full px-3 gap-x-2">
                  <div className="text-white font-bold text-lg 2xl:text-xs">
                    {(
                      (data.projectOfTheWeek.circulatingSupply /
                        data.projectOfTheWeek.totalSupply) *
                      100
                    ).toFixed()}
                    %
                  </div>
                  <div className="h-1 grow bg-white/80 rounded-sm relative">
                    <div
                      className="h-full bg-ui-red rounded-sm absolute left-0"
                      style={{
                        width:
                          (data.projectOfTheWeek.circulatingSupply /
                            data.projectOfTheWeek.totalSupply) *
                            100 +
                          "%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full  rounded-sm h-fit mt-2.5 2xl:mt-1 p-2.5 2xl:p-1">
              <div className="font-bold oswald text-xl 2xl:text-xs">
                Why do we like {data.projectOfTheWeek.project_title || 'Banana'}?
              </div>
              <div className="text-sm 2xl:text-[12px] mt-1 leading-none">
                {data.projectOfTheWeek.feedback}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full flex items-center justify-center h-full"><Loader /></div>
      )}
    </div>
  );
};
