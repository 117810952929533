import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  convertTimestampToDateString,
} from "../../../utils/chartOptions";
import { useEffect, useRef, useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const colorSetting = {
  "GBTC (Grayscale)": "#7400B866",
  "BITB (Bitwise)": "#6930C366",
  "IBIT (BlackRock)": "#5E60CE66",
  "HODL (VanEck)": "#5390D966",
  "EZBC (Franklin Templeton)": "#4EA8DE66",
  "BTCO (Invesco Galaxy)": "#48BFE366",
  "BRRR (Valkyrie)": "#56CFE166",
  "FBTC (Fidelity)": "#64DFDF66",
  "DEFI (Hashdex)": "#72EFDD66",
  "ARKB (Ark 21Shares)": "#80FFDB66",
  "BTCW (WisdomTree)": "#33F5BE66",
  "BTC (Grayscale Mini)": "#00FFB766",
};
// set the barchart colors
function setColor(key) {
  if (key === "TOTAL") {
    return function test(context) {
      return context.raw < 0 ? "#ff0000" : "#00FF00";
    };
  } else {
    /*     colorSetting[key] = generateRandomHexColor(40);
    console.log(colorSetting); */
    return colorSetting[key];
  }
}
function generateChartData(labels, dataset) {
  const providers = dataset.providers;
  const providersKeys = Object.keys(providers).filter(
    (e) => e !== "101" && e !== "102" && e !== "103"
  );
  const datasets = [];
  providersKeys.forEach((key) => {
    datasets.push({
      label: providers[key],
      data: dataset.chart4.map((e) => e[key]),
      backgroundColor: setColor(providers[key]),
      borderSkipped: "bottom",
      yAxisID: "y",
    });
  });
  datasets.push({
    label: "Price",
    data: dataset.chart4.map((e) => e["price"]),
    backgroundColor: "#ff6b70",
    borderSkipped: "bottom",
    borderColor: "#ff6b70",
    type: "line",
    order: 0,
    borderWidth: 1,
    pointRadius: 1,
    yAxisID: "y1",
  });
  return {
    labels,
    datasets,
  };
}
const InflowsChart = ({ data, btcPrice }) => {
  const [toggleInflow, setToggleInflow] = useState(false);
  const chartRef = useRef(null); // Added ref
  const labels = data.chart4.map((el) => convertTimestampToDateString(el.ts));
  const peChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          filter: function(item, chart) {
            // Logic to remove a particular legend item goes here
            if(toggleInflow) {
              return !item.text.includes('TOTAL')
            } else {
              return item.text.includes('Price') || item.text.includes('TOTAL')
            }

          }
        }
      },
      title: {
        display: false,
        text: "",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            console.log(context);
            if (context.dataset.label === "Price") {
              return `Price: $${Number(context.raw).toFixed(2)}`;
            } else {
              return `${context.dataset.label}: ₿${Number(
                context.raw
              ).toFixed()} | $${(
                (Number(context.raw) * btcPrice) /
                1_000_000
              ).toFixed()}M`;
            }
          },
        },
      },
    },
    scales: {
      y: {
        stacked: true,
        grid: {
          color: "#e0e0e0",
        },
        ticks: {
          color: "#e0e0e0",
        },
      },
      y1: {
        display: true,
        position: "right",
        beginAtZero: false,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          color: "#e0e0e0",
          callback: function (value, index, ticks) {
            return "$" + (Math.round(value * 100) / 100).toFixed();
          },
        },
      },
      x: {
        stacked: true,
        ticks: {
          color: "#e0e0e0",
        },
      },
    },
  };

  const tester = () => {
    chartRef.current.data.datasets.forEach((dataset) => {
      if (dataset.label !== "Price") {
        dataset.hidden = !dataset.hidden;
      }
    });
    chartRef.current.update();
    setToggleInflow(!toggleInflow);
  };
  useEffect(() => {
    if (chartRef) {
      chartRef.current.data.datasets.forEach((dataset) => {
        if (dataset.label !== "TOTAL" && dataset.label !== "Price") {
          dataset.hidden = !dataset.hidden;
        }
      });
      chartRef.current.update();
    }
  }, []);
  return (
    <div className="w-full h-full flex flex-col items-center z-10">
      <button
        onClick={() => tester()}
        className="my-2 font-bold uppercase text-sm border border-white/50 rounded-lg py-1 px-2 hover:bg-white/20  hover:-translate-y-1 hover:shadow-black/30 hover:shadow-lg transition-all duration-200 "
      >
        {!toggleInflow ? "Show details" : "SHOW INFLOW"}
      </button>
      <Bar
        options={peChartoptions}
        data={generateChartData(labels, data)}
        ref={chartRef}
      />
      {/* Connected ref */}
    </div>
  );
};

export default InflowsChart;
