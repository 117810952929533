import { useSetPoints } from "../hooks/use-set-points";
import Button from "./Button";
import { useState } from "react";

const LinkButton = ({
  linkPath,
  label,
  icon,
  disabled,
  isWip,
  isActive,
  pointsAction,
}) => {
  const { setPoints } = useSetPoints();
  /* const path = usePathname() */
  const [forceDisable, setForceDisable] = useState(false);
  const clickHandler = async () => {
    if (!pointsAction) return;
    setForceDisable(true)
    await setPoints(pointsAction);
    window.location.href = linkPath;
    setForceDisable(false)
  };
  if (pointsAction)
    return (
      <div
        className={`max-md:text-xl ${disabled || forceDisable ? "pointer-events-none" : ""}`}
        onClick={() => clickHandler()}
      >
        <Button label={label} icon={icon} isWip={isWip} active={isActive} />
      </div>
    );
  return (
    <a
      href={linkPath}
      className={`max-md:text-xl ${disabled  ? "pointer-events-none" : ""}`}
    >
      <Button label={label} icon={icon} isWip={isWip} active={isActive} />
    </a>
  );
};

export default LinkButton;
