import { create } from 'zustand'

export const usePeDataStore = create((set) => ({
  peData: null,
  sortedPeData: null,
  peGraphData: null,
  labels: null,
  backendError: false,
  lastUpdate: null,
  setLastUpdate: (val) => set(() => ({ lastUpdate: val })),
  setPeData: (data) => set(() => ({ peData: data })),
  setSortedPeData: (data) => set(()=>({sortedPeData: data})),
  setPeGraphData: (data) => set(()=>({peGraphData: data})),
  setLabels: (data) => set(()=>({labels: data})),
  setBackendError: (data) => set(()=>({backendError: data})),
  resetPeData: () => set({ peData: null }),
}))