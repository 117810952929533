import { cn } from "../utils/cn";
import BcLogoSmall from "./BCLogoSmall";

export const BcSign = ({ className }) => {
  return (
    <div
      className={cn(
        "absolute right-20 bottom-24 bg-ui-dark-gray rounded-xl flex flex-row items-center pointer-events-none shadow-lg p-2",
        className
      )}
    >
      <img src="/SVG/full-color.svg" alt="bc logo" class="w-[40px]"></img>
    </div>
  );
};
