import { Helmet } from "react-helmet";
import AuthenticatedContainer from "../components/containers/AuthenticatedContainer";
import PEMainCharts from "../components/containers/PE/PEMainCharts";
import { useEffect } from "react";
import { useSetPoints } from "../hooks/use-set-points";
import { points } from "../utils/pointsActions";

export default function PEAnalysis() {
  const { setPoints } = useSetPoints();

  useEffect(() => {
    const addPoints = async (pointsAction) => {
      await setPoints(pointsAction);
    }
    addPoints(points.visit_pe_page).catch(console.error);
  })
  return (
    <main className="w-full h-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
      <Helmet>
        <title>
          Crypto P/E Analysis Dashboard | Real-Time Token Valuations
        </title>
        <meta
          name="description"
          content="Compare cryptocurrency P/E ratios, track token revenues, and discover undervalued projects. Advanced market metrics and historical trends for 100+ tokens."
        />
      </Helmet>
      <AuthenticatedContainer>
        <PEMainCharts />
      </AuthenticatedContainer>
    </main>
  );
}
