import { useState, useEffect } from "react";
import axios from "axios";
const isDev = Boolean(process.env.REACT_APP_IS_DEV_MODE) || false;
export const useGetFarmHistoryData = (project, symbol, poolMeta, chain) => {
  const [farmTableHistoryData, setTableFarmHistoryData] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [chartData, setChartData] = useState(null); // State for processed chart data
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  const processChartData = (graphInfo) => {
    const timestamps = graphInfo.map((entry) =>
      new Date(entry.timestamp).toLocaleDateString()
    );

    const ratio30dTo1d = graphInfo.map((entry) => {
      const dailyApy30d = entry.dailyApy30d ? entry.dailyApy30d : 0;
      const dailyApy1d = entry.dailyApy1d ? entry.dailyApy1d : 0;

      return dailyApy1d > 0 ? dailyApy30d / dailyApy1d : 0;
    });

    return {
      labels: timestamps,
      datasets: [
        {
          label: "30dApy / 1dApy Ratio",
          data: ratio30dTo1d,
          borderSkipped: "bottom",
          borderColor: "#D22326",
          backgroundColor: "#D22326",
          tension: 0.4,
          borderWidth: 2,
          borderJoinStyle: "round",
          radius: 1,
          pointStyle: false,
          pointBackgroundColor: "transparent",
          point: {
            radius: 0,
            pointStyle: "cirlce",
            backgroundColor: "transparent",
          },
        },
      ],
    };
  };

  useEffect(() => {
    if (!project || !symbol) return;

    setIsLoading(true);
    axios
      .get(
        `https://${
          isDev ? "dev" : "api"
        }.bc.army/api/bestfarms_by_project?project=${project}&symbol=${symbol}&poolMeta=${poolMeta}&chain=${chain}`
      )
      .then((response) => {
        const data = response.data.Data;
        setTableFarmHistoryData(data.graphInfo); // Set raw graph data

        setProjectData(data.projectInfo); // Set project info

        const processedChartData = processChartData(data.graphInfo); // Process chart data
        setChartData(processedChartData); // Set processed chart data
        if(response.data.last_updated){
          setLastUpdated(response.data.last_updated);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching farm history data:", error);
        setError(error);
        setIsLoading(false);
      });
  }, [project, symbol]);

  return { farmTableHistoryData, projectData, chartData, isLoading, error, lastUpdated };
};
