import { useCallback, useEffect, useState } from "react";
import axios from "axios";
const isDev = Boolean(process.env.REACT_APP_IS_DEV_MODE) || false;
export const useGetPoints = ({ address }) => {
  const [points, setPoints] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetcher = useCallback(async () => {
    setIsLoading(true);
    axios
      .get(
        `https://${isDev ? "dev" : "api"}.bc.army/api/get_points/${address}/`
      )
      .then(function (response) {
        // handle success
        console.log("setting points", response.data.points);
        setPoints(response.data.points);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  }, [address]);
  useEffect(() => {
    if (!address) return;
    fetcher();
  }, [address, fetcher]);
  
  return { points, isLoading };
};
