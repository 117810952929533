import { Helmet } from "react-helmet";
import Loader from "../components/Loader";

import { useGetLeaderboard } from "../hooks/use-get-leaderboard";
import { useMemo } from "react";
import DataTable from "react-data-table-component";

function Leaderboard() {
  const { points, isLoading } = useGetLeaderboard();
 const columns = useMemo(() => {
    return [
      {
        name: "Wallet",
        selector: (row) => row.wallet_id,
        sortable: false,
        minWidth: "120px",
      },
      {
        name: "Points",
        selector: (row) => row.points,
        sortable: true,
        minWidth: "80px",
      }
    ];
  }, []);
  if (isLoading) {
    return (
      <main className="w-full h-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
        <div className="w-full flex items-center justify-center flex-grow">
          <div className="flex items-center flex-col">
            <Loader />
            <div className="text-white/70 text-sm">Loading Data...</div>
          </div>
        </div>
      </main>
    );
  }
  return (
    <div className="w-full h-fit min-h-screen flex items-center pt-16 pb-5 px-5 md:px-10">
      <Helmet>
        <title>$ARMY | Web3 Market Intelligence & Analytics Platform</title>
        <meta
          name="description"
          content="Access professional-grade crypto analytics, P/E analysis, burn tracking, and yield farming tools. Join the leading web3 intelligence platform powered by $ARMY token."
        />
      </Helmet>
      <div className="w-full flex flex-col items-start relative">
      <h2 className="text-[22px] text-neutral-200 font-semibold mt-5 mb-5">
        Leaderboard
      </h2>

      {points ? (
        <>
          <div className="w-full border border-neutral-300/20 rounded-md flex flex-col overflow-auto h-fit relative">
            <img
              src="/SVG/pic-nero.svg"
              alt="bc logo"
              className="aspect-square pointer-events-none] h-[70%] w-auto absolute left-1/2 top-1/3 md:top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10"
            />
            <DataTable
              columns={columns}
              data={points}
              pagination
              fixedHeader={true}
              theme="armyTheme"
              paginationPerPage={50}
              paginationRowsPerPageOptions={[50, 100, 250]}
            />
          </div>
        </>
      ) : (
        <div><Loader /></div>
      )}
      </div>
    </div>
  );
}

export default Leaderboard;
