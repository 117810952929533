import { useEffect, useState } from "react";
import axios from "axios";
import { uniq } from "lodash";
const isDev = Boolean(process.env.REACT_APP_IS_DEV_MODE) || false;
export const useGetBestFarms = () => {
  const [bestFarmsData, setBestFarmsData] = useState(null);
  const [chains, setChains] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [backendError, setBackendError] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://${isDev ? "dev" : "api"}.bc.army/api/latest_bestfarms_data`)
      .then(function (response) {
        // handle success
        setBestFarmsData(response.data.Data);
        if (response.data.Data) {
          const uniqueChains = uniq(
            response.data.Data.map((item) => item.chain)
          );
          uniqueChains.unshift("All Chains");

          const uniqProjects = uniq(
            response.data.Data.map((item) => item.project)
          );
          uniqProjects.unshift("All Projects");
          setProjects(uniqProjects);
          setChains(uniqueChains);
        }
        if (response.data.last_updated) {
          setLastUpdated(response.data.last_updated);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setBackendError(true);
        setLoading(false);
      });
  }, []);
  return {
    bestFarmsData,
    loading,
    chains,
    projects,
    backendError,
    lastUpdated,
  };
};
