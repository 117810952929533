export const points = {
  visit_pe_page: 'visit_pe_page', // ok
  single_pe_pair: 'single_pe_pair', // ok
  watchlist_pe_pair: 'watchlist_pe_pair',
  single_bestfarms: 'single_bestfarms',// ok
  watchlist_bestfarms: 'watchlist_bestfarms',
  visit_bestfarms: 'visit_bestfarms',  // ok
  visit_pe: 'visit_pe',
  share_on_twitter: 'share_on_twitter', // ok
  telegram_or_x_button: 'telegram_or_x_button',  // ok
  trade_with_banana: 'trade_with_banana',
  overview: 'overview',
  blur_loans: 'blur_loans'
};

export const pointsValue = {
  visit_pe_page: 1,
  single_pe_pair: 2,
  watchlist_pe_pair: 2,
  single_bestfarms: 2,
  watchlist_bestfarms: 2,
  visit_bestfarms: 1,
  visit_pe: 1,
  share_on_twitter: 2,
  telegram_or_x_button: 3,
  trade_with_banana: 5,
  overview: 1,
  blur_loans: 2
}
