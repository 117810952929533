import { useNavigate, useLocation } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa6";
import DataTable from "react-data-table-component";

import Loader from "../../Loader";
import { columns } from "../../../utils/columnsConfig";
import { useGetFarmHistoryData } from "../../../hooks/use-get-farm-history-data";
import { InfoAndChartWrapper } from "./InfoAndChartWrapper";
import { useEffect } from "react";
import { fullDate } from "../../../utils/chartOptions";

export const FarmHistoryWrapper = ({ farmId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const project = query.get("p");
  const symbol = query.get("s");
  const poolMeta = query.get("pm");
  const chain = query.get("c");

  const {
    farmTableHistoryData,
    projectData,
    chartData,
    isLoading,
    error,
    lastUpdated,
  } = useGetFarmHistoryData(project, symbol, poolMeta, chain);

  const handleGoBack = () => {
    navigate("/best-farms");
  };
  if (isLoading) {
    return (
      <main className="w-full h-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
        <div className="w-full flex items-center justify-center flex-grow">
          <div className="flex items-center flex-col">
            <Loader />
            <div className="text-white/70 text-sm">
              {error ? "An error occoured" : "Loading Data... "}
            </div>
          </div>
        </div>
      </main>
    );
  }

  if (!chartData || !projectData || error) {
    return (
      <main className="w-full h-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
        <div className="w-full flex items-center justify-center flex-grow">
          <div className="flex items-center flex-col">
            <Loader />
            <div className="text-white/70 text-sm">
              {JSON.stringify(location.state)}
              {error ? "An error occoured" : "Error... "}
            </div>
          </div>
        </div>
      </main>
    );
  }

  return (
    <div className="w-full flex flex-col flex-grow relative pt-10 md:pt-16">
      <div
        className="flex flex-row items-center hover:text-ui-red cursor-pointer text-sm mb-4"
        onClick={handleGoBack}
      >
        <FaChevronLeft /> Go back
      </div>
      <InfoAndChartWrapper projectData={projectData} chartData={chartData} />

      <div className="w-full my-2 border border-neutral-300/20 rounded-sm flex flex-col overflow-auto h-fit relative">
        <img
          src="/SVG/pic-nero.svg"
          alt="bc logo"
          className="aspect-square pointer-events-none] h-[20%] w-auto absolute left-1/2 top-1/3 md:top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10"
        />

        {farmTableHistoryData && (
          <DataTable
            columns={columns}
            data={farmTableHistoryData}
            pagination
            fixedHeader={true}
            theme="armyTheme"
            paginationPerPage={50}
            paginationRowsPerPageOptions={[50, 100, 250]}
          />
        )}
      </div>
      <div className="mt-2 mb-5 text-xs text-red-300/70">
        Last updated: {lastUpdated && fullDate(lastUpdated)}
      </div>
    </div>
  );
};
