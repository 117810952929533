import { Helmet } from "react-helmet";
import { BurnCard } from "../components/BurnCard";
import { useAppKitAccount } from "@reown/appkit/react";


function BurnPage() {
  const tokens = [
    {
      classHover: "banana-shadow ",
      img: "/tokens/banana.png",
      path: "https://burn.bc.army/banana",
    },
    {
      classHover: "dork-shadow ",
      img: "/tokens/dork.png",
      path: "https://burn.bc.army/dork",
    },
    {
      classHover: "alpaca-shadow ",
      img: "/tokens/alpaca.png",
      path: "https://burn.bc.army/alpaca",
    },
    {
      classHover: "rollbit-shadow ",
      img: "/tokens/rollbit.png",
      path: "https://burn.bc.army/rollbit",
    } /* 
    {
      classHover: "woof-shadow",
      img: "/tokens/woof.png",
      path: "https://burn.woofwork.io/",
    }, */,
    {
      classHover: "dex-shadow ",
      img: "/tokens/dex.png",
      path: "https://burn.dextools.io/",
    },
  ];
  const { address, isConnected, caipAddress, status } = useAppKitAccount()

  return (
    <main className="w-full h-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-2 gap-y-5">
      <Helmet>
        <title>Crypto Token Burn Tracker | Real-Time Burn Analytics</title>
        <meta
          name="description"
          content="Track token burn mechanics across major projects like DEXTools, BananaGun, and more. Live burn data, supply reduction metrics, and impact analysis."
        />
      </Helmet>
      <div className="oswald text-3xl font-bold uppercase">Burn Dashboards</div>
      <div className=" grid grid-cols-2 md:grid-cols-3 gap-10 items-center justify-center ">
        {tokens.map((t) => (
          <BurnCard
            key={t.classHover}
            classHover={t.classHover}
            path={t.path}
            img={t.img}
          />
        ))}
      </div>
    </main>
  );
}
export default BurnPage;
