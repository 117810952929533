import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";

const FavCard = ({ infos, i, hasBorder }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/pe-analysis/${infos.gecko_id}`);
  };
  return (
    <div
      className={`w-full font-mono text-sm cursor-pointer ${
        i < 3 && "md:border-r md:border-r-neutral-300/20"
      } ${hasBorder && "border-r border-r-neutral-300/20 !h-auto"} ${
        i % 2 === 0 && "max-md:border-r max-md:border-r-neutral-300/20"
      }  ${i > 1 && "max-md:border-t max-md:border-t-neutral-300/20"}`}
      onClick={() => handleClick()}
    >
      <div className="flex flex-row justify-between w-full border-b border-neutral-300/20 p-2">
        {infos.name}{" "}
        {infos.logoUrl ? (
          <img
            src={infos.logoUrl}
            alt="Logo"
            className="h-5 w-5 rounded-full"
          />
        ) : (
          <div className="h-5 w-5 bg-red-700 rounded-full"></div>
        )}
      </div>
      <div className="p-2">
        <div className="flex flex-row justify-between">
          <div className="text-xs">PE 30d</div>{" "}
          <div className="font-semibold">{infos.pe_ratio.toFixed(2)}</div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="text-xs">Revenues 30d</div>{" "}
          <div className="font-semibold">
            ${infos.revenue_30d.toLocaleString()}
          </div>
        </div>
        {infos.feedback && (
          <div className="flex flex-col  text-xs mt-2">
            <div>{infos.feedback}</div>
          </div>
        )}
      </div>
    </div>
  );
};
export const FourtFav = ({ data }) => {
  if (data.length < 5)
    return (
      <div className="w-full mt-6 mb-2.5">
        <div className="text-md mb-1">On our watchlist</div>
        <div className="grid grid-cols-2 md:grid-cols-4 rounded-md border border-neutral-300/20">
          {data.map((infos, i) => (
            <FavCard infos={infos} key={infos.id + i} i={i} />
          ))}
        </div>
      </div>
    );
  return (
    <div className="w-full mt-6 mb-2.5">
      <div className="text-md mb-1">On our watchlist</div>
      <div className=" w-full rounded-md border border-neutral-300/20">
        <Swiper
          slidesPerView={2}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 2,
            },
            // when window width is >= 640px
            900: {
              slidesPerView: 4,
            },
            1500: {
              slidesPerView: 5,
            },
          }}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          className="w-full h-auto"
          loop
          modules={[Autoplay]}
          autoplay={{
            delay: 4000,
          }}
        >
          {data.map((infos, i) => (
            <SwiperSlide
              i={i}
              className="md:border-r md:border-r-neutral-300/20 !h-auto"
            >
              <FavCard infos={infos} key={infos.id + i} hasBorder={true} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
