import React from "react";

const ProjectInformation = ({ projectData, cartData }) => {
  if (!projectData) {
    return <p>Loading project information...</p>;
  }

  return (
    <div className="flex flex-col md:flex-row w-1/3">
      <div className="w-full flex flex-col border border-neutral-300/20 rounded-sm p-4">
        <div className="text-lg md:text-xl md:mb-2.5">
          Project Information
        </div>
        <div className=" flex flex-col items-start justify-start gap-5 my-auto">
          {Object.entries(projectData).map(([key, value], index) => (
            <div
              key={index}
              className="flex flex-col items-start text-md px-2.5 py-2 border border-neutral-300/20 rounded-md min-w-full h-[60px]"
            >
              <div
                key={index}
                className="flex flex-row items-center gap-6 w-full h-[60px]"
              >
                <div className="text-neutral-400 font-light text-md  ">
                  {key[0].toUpperCase() + key.slice(1)}:
                </div>
                <div className="font-semibold w-91 ml-auto">{value}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectInformation;
