import { useEffect, useState } from "react";
import axios from "axios";
import { usePeDataStore } from "../store/peData";
import { set, uniqBy } from "lodash";
import { peRatioSorter } from "../utils/dataCleaning";
const isDev = Boolean(process.env.REACT_APP_IS_DEV_MODE) || false;
export const useGetPeData = () => {
  const {
    peData,
    setPeData,
    sortedPeData,
    setSortedPeData,
    peGraphData,
    labels,
    setLabels,
    backendError,
    setBackendError,
    lastUpdate,
    setLastUpdate,
  } = usePeDataStore((state) => {
    return {
      peData: state.peData,
      setPeData: state.setPeData,
      sortedPeData: state.sortedPeData,
      setSortedPeData: state.setSortedPeData,
      peGraphData: state.peGraphData,
      setPeGraphData: state.setPeGraphData,
      labels: state.labels,
      setLabels: state.setLabels,
      backendError: state.backendError,
      setBackendError: state.setBackendError,
      lastUpdate: state.lastUpdate,
      setLastUpdate: state.setLastUpdate,
    };
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (peData || sortedPeData || peGraphData) return;
    setLoading(true);
    axios
      .get(`https://${isDev ? "dev" : "api"}.bc.army/api/latest-pe-data/`)
      .then(function (response) {
        // handle success
        if (response?.data?.peList) {
          const uniqList = uniqBy(response.data.peList, "id")
            .filter((e) => e.peRatio > 0)
            .map((el) => {
              return {
                ...el,
                ...el.revenues,
              };
            });
          /* const median = uniqList[Math.floor(uniqList.length / 2)].peRatio; */
          const filtered = uniqList.map((row) => {
            return {
              ...row,
              percToHolders: row.holdersRevenue / row.revenues.revenue1day,
              peDaily: Number(
                (row.marketCap / (row.revenues.revenue1day * 365)).toFixed(3)
              ),
              peWeekly: Number(
                (row.marketCap / (row.revenues.revenue1week * 52)).toFixed(3)
              ),
            };
          });
          const sorted = peRatioSorter(filtered.filter((e) => e.peRatio < 99));
          setPeData(filtered);
          setSortedPeData(sorted);
          setLabels(sorted.map((e) => e.title));
        }
        if (response?.data?.last_updated) {
          console.log(response.data.last_updated)
          setLastUpdate(response.data.last_updated);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setBackendError(true);
        setLoading(false);
      });
  }, []);
  return { peData, labels, sortedPeData, peGraphData, loading, backendError, lastUpdate };
};
