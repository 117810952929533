import { useEffect } from "react";
import { Helmet } from "react-helmet";
import AuthenticatedContainer from "../components/containers/AuthenticatedContainer";
import { BestFarmsWrapper } from "../components/containers/BestFarms/BestFarmsWrapper";
import { useSetPoints } from "../hooks/use-set-points";
import { points } from "../utils/pointsActions";
export default function BestFarms() {
  const { setPoints } = useSetPoints();

  useEffect(() => {
    const addPoints = async (pointsAction) => {
      await setPoints(pointsAction);
    }
    addPoints(points.visit_bestfarms).catch(console.error);
  })
  return (
    <main className="w-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
      <Helmet>
        <title>Best Yield Farms | DeFi Yield Aggregator & Analytics</title>
        <meta
          name="description"
          content="Find the most profitable yield farming opportunities across multiple chains. Real-time APR tracking, risk assessment, and impermanent loss projections."
        />
      </Helmet>
      <AuthenticatedContainer>
        <BestFarmsWrapper />
      </AuthenticatedContainer>
    </main>
  );
}
