const PeRevenues = ({ numbers }) => {
  const peDaily = (
    numbers.marketCap /
    (numbers.revenues.revenue1day * 365)
  ).toFixed(3);
  const peWeekly = (
    numbers.marketCap /
    (numbers.revenues.revenue1week * 52.17)
  ).toFixed(3);
  const peMonthly = (
    numbers.marketCap /
    (numbers.revenues.revenue1month * 12)
  ).toFixed(3);
  return (
    <div className="max-md:hidden w-full mb-5 flex flex-col">
      <div className="text-xl mb-2.5">Revenues</div>
      <div className="flex flex-grow max-h-56 w-full border border-neutral-300/20  p-2.5 rounded-sm items-center justify-center relative">
        <img src="/SVG/pic-nero.svg" alt="bc logo" className="h-[150px] w-auto invert opacity-10" />
        <div className="absolute w-[calc(100%)] h-full grid grid-cols-2 grid-rows-3 ">
          <div className="grid col-span-2 border-b border-b-neutral-300/20 grid-cols-3">
            <div className="border-r border-r-neutral-300/20  flex flex-col items-center justify-center">
              <div className="text-neutral-400 font-light text-md">
                P/E (daily)
              </div>
              <div className="font-semibold">
                {peDaily < 0 ? "1000+" : peDaily}
              </div>
            </div>
            <div className="border-r border-r-neutral-300/20 flex flex-col items-center justify-center">
              <div className="text-neutral-400 font-light text-md">
                P/E (weekly)
              </div>
              <div className="font-semibold">
                {peWeekly < 0 ? "1000+" : peWeekly}
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="text-neutral-400 font-light text-md">
                P/E (monthly)
              </div>
              <div className="font-semibold">
                {peMonthly <0 ? "1000+" : peMonthly}
              </div>
            </div>
          </div>
          <div className="grid col-span-2 border-b border-b-neutral-300/20 grid-cols-3">
            <div className="border-r border-r-neutral-300/20 border-b border-b-neutral-300/20 flex flex-col items-center justify-center">
              <div className="text-neutral-400 font-light text-md">
                Revenue (24h)
              </div>
              <div className="font-semibold">
                ${numbers.revenue1day.toLocaleString()}
              </div>
            </div>
            <div className="border-r border-r-neutral-300/20 border-b border-b-neutral-300/20 flex flex-col items-center justify-center">
              <div className="text-neutral-400 font-light text-md">
                Revenue (7d)
              </div>
              <div className="font-semibold">
                ${numbers.revenue1week.toLocaleString()}
              </div>
            </div>
            <div className="border-r border-r-neutral-300/20 flex flex-col items-center justify-center">
              <div className="text-neutral-400 font-light text-md">
                Revenue (30d)
              </div>
              <div className="font-semibold">
                ${numbers.revenue1month.toLocaleString()}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center col-span-2 ">
            <div className="text-neutral-400 font-light text-md">
              Cumulative Revenue
            </div>
            <div className="font-semibold">
              ${numbers.cumulativeRevenue.toLocaleString()}
            </div>
          </div>
        </div>
        {/*  <div className="text-md mb-2 w-full border-b border-b-neutral-300/30">{col1.title}</div>
        <p className="text-xs">{col1.paragraph}</p> */}
      </div>
      {/* <div className="flex flex-grow w-full border border-neutral-300/20  rounded-sm p-5">
        <Bar
          options={peChartoptions}
          data={generateChartData(labels, numbers)}
        />
      </div> */}
    </div>
  );
};

export default PeRevenues;
