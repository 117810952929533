import { ColTitle } from "../components/containers/BestFarms/Columns";
const dateFormatter = (isoDate) => {
  if(!isoDate) return "";
  // Create a Date object
  const date = new Date(isoDate);

  // Extract day, month, and year
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getUTCFullYear();

  // Format the date as DD-MM-YYYY
  return `${day}-${month}-${year}`;
};
export const columns = [
  {
    name: "Date",
    selector: (row) => dateFormatter(row.timestamp),
    sortable: true,
  },
  {
    name: <ColTitle name="TVL (USD)" />,
    selector: (row) => row.tvlUsd,
    sortable: true,
    format: (row) => `$ ${row.tvlUsd.toLocaleString()}`,
    minWidth: "140px",
    right: true,
  },
  {
    name: <ColTitle name="7D APY" />,
    selector: (row) => row.apyBase7d,
    sortable: true,
    format: (row) =>
      row.apyBase7d <= 0
        ? "-"
        : `${row.apyBase7d.toFixed(2).toLocaleString()}%`,
    minWidth: "140px",
    right: true,
  },
  {
    name: <ColTitle name="1D APY" />,
    selector: (row) => row.apyBase1d,
    sortable: true,
    format: (row) =>
      row.apyBase1d <= 0
        ? "-"
        : `${row.apyBase1d.toFixed(2).toLocaleString()}%`,
    minWidth: "140px",
    right: true,
  },
  {
    name: <ColTitle name="1D Volume" />,
    selector: (row) => row.volumeUsd1d,
    sortable: true,
    format: (row) => `$ ${row.volumeUsd1d.toLocaleString()}`,
    minWidth: "140px",
    right: true,
  },
  {
    name: <ColTitle name="7D Volume" />,
    selector: (row) => row.volumeUsd7d,
    sortable: true,
    format: (row) => `$ ${row.volumeUsd7d.toLocaleString()}`,
    minWidth: "140px",
    right: true,
  },
  {
    name: <ColTitle name="Daily APR 30D" />,
    selector: (row) => row.dailyApy30d,
    sortable: true,
    format: (row) =>
      !row.dailyApy30d || row.dailyApy30d <= 0
        ? "-"
        : `${row.dailyApy30d ? row.dailyApy30d.toFixed(4) : 0}%`,
    minWidth: "140px",
    right: true,
  },
  {
    name: <ColTitle name="Daily APR 7D" />,
    selector: (row) => row.dailyApy7d,
    sortable: true,
    format: (row) =>
      !row.dailyApy7d || row.dailyApy7d <= 0
        ? "-"
        : `${row.dailyApy7d ? row.dailyApy7d.toFixed(4) : 0}%`,
    minWidth: "140px",
    right: true,
  },
  {
    name: <ColTitle name="Daily APR 1D" />,
    selector: (row) => row.dailyApy1d,
    sortable: true,
    format: (row) =>
      !row.dailyApy1d || row.dailyApy1d <= 0
        ? "-"
        : `${row.dailyApy1d ? row.dailyApy1d.toFixed(4) : 0}%`,
    minWidth: "140px",
    right: true,
  },
];
