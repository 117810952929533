import axios from "axios";
import { useAppKitAccount } from "@reown/appkit/react";
import { toast } from "react-toastify";
import { pointsValue } from "../utils/pointsActions";


const isDev =
Boolean(process.env.REACT_APP_IS_DEV_MODE) || false;
export const useSetPoints = () => {
  const { address, isConnected } = useAppKitAccount()

  const setPoints = async (action) => {
    if(!isConnected || !action || !address) return
    await axios.post(`https://${isDev ? 'dev' : 'api'}.bc.army/api/add_point`,{
      wallet_id: address,
      action
    })
    .then(function (response) {

      const notify = () => toast(`+ ${pointsValue[action]} POINT${pointsValue[action] > 1 ? 'S' : ''} 🔥`);
      notify()
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  return { setPoints };
};
