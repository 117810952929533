import DataTable, { createTheme } from "react-data-table-component";
import { useGetBestFarms } from "../../../hooks/use-get-best-farms";
import Loader from "../../Loader";
import { useEffect, useMemo, useState } from "react";
import { TableFilters } from "./TableFilters";
import { ColTitle } from "./Columns";
import {
  apyBase1dIntervals,
  apyBase30dIntervals,
  tvlUsdIntervals,
} from "./utilsFarms";
import { BEErrorBanner } from "../../BEErrorBanner";
import { useNavigate } from "react-router-dom";
import { useGetHighlightedFarms } from "../../../hooks/use-get-highlighted-farms";
import { HighlightedFarms } from "../FarmHistory/HighlightedFarms";
import { fullDate } from "../../../utils/chartOptions";

createTheme(
  "armyTheme",
  {
    text: {
      primary: "#fff",
      secondary: "#d4d4d4",
    },
    background: {
      default: "transparent",
    },
    context: {
      background: "#bf2123",
      text: "#FFFFFF",
    },
    divider: {
      default: "#d4d4d433",
    },
    button: {
      default: "#bf2123",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "#2aa198",
    },
  },
  "dark"
);

export const BestFarmsWrapper = () => {
  const navigate = useNavigate();

  const {
    bestFarmsData,
    loading,
    chains,
    projects,
    backendError,
    lastUpdated,
  } = useGetBestFarms();
  const { highlightedFarms, loadingHighlightedFarms } =
    useGetHighlightedFarms();
  const [filteredData, setFilteredData] = useState(bestFarmsData);
  const [apy1dFilter, setApy1dFilter] = useState([]);
  const [apy30dFilter, setApy30dFilter] = useState([]);
  const [tvlUsdFilter, setTvlUsdFilter] = useState([]);
  const [onlyStable, setOnlyStable] = useState(false);
  const [selectedChain, setSelectedChain] = useState("All Chains");
  const [selectedProject, setSelectedProject] = useState("All Projects");
  const [symbolName, setSymbolName] = useState("");

  const handleFilterTvlUsd = (val) => {
    if (tvlUsdFilter.includes(val)) {
      setTvlUsdFilter(tvlUsdFilter.filter((item) => item !== val));
    } else {
      const newState = [...tvlUsdFilter];
      newState.push(val);
      setTvlUsdFilter(newState);
    }
  };
  const handleFilterApy1d = (val) => {
    if (apy1dFilter.includes(val)) {
      setApy1dFilter(apy1dFilter.filter((item) => item !== val));
    } else {
      const newState = [...apy1dFilter];
      newState.push(val);
      setApy1dFilter(newState);
    }
  };
  const handleFilterApy30d = (val) => {
    if (apy30dFilter.includes(val)) {
      setApy30dFilter(apy30dFilter.filter((item) => item !== val));
    } else {
      const newState = [...apy30dFilter];
      newState.push(val);
      setApy30dFilter(newState);
    }
  };
  const isValueInIntervarls = (number, selections, intervals) => {
    const selectedIntervals = intervals.filter((interval) =>
      selections.includes(interval.id)
    );
    return selectedIntervals.some(
      (interval) =>
        number >= interval.extremes[0] && number < interval.extremes[1]
    );
  };
  const handleClick = (row) => {
    /*  FOR KASHIF 
     HERE WHEN YOU CLICK ON A FARM, YOU GET REDIRECTED TO THE FARM HISTORY PAGE.
     RIGHT NOW I CREATED THE ID BY CHAINING THE SYMBOL, PROJECT AND CHAIN TOGETHER.
     DEPENDING ON HOW OMER WANTS TO BUILD THE ID'S OF FARMS, YOU CAN CHANGE THE ID BELLOW!
    */
    if (!row.symbol) return;
    navigate(
      `/best-farms/history?s=${row.symbol}&p=${row.project}&c=${row.chain}&pm=${row.poolMeta}`,
      {
        state: {
          symbol: row.symbol,
          project: row.project,
          poolMeta: row.poolMeta,
        },
      }
    );
  };
  const columns = useMemo(() => {
    return [
      {
        name: "Symbol",
        selector: (row) => row.symbol,
        format: (row) => (
          <div
            className="cursor-pointer w-full flex items-center flex-row"
            onClick={() => handleClick(row)}
          >
            <span className="">{row.symbol}</span>
          </div>
        ),
        sortable: true,
        minWidth: "140px",
      },
      {
        name: "Project",
        selector: (row) => row.project,
        sortable: true,
        minWidth: "140px",
      },
      {
        name: "Chain",
        selector: (row) => row.chain,
        sortable: true,
        minWidth: "100px",
      },
      {
        name: (
          <ColTitle
            name="TVL (USD)"
            showInfo={
              "Lorem ipsum dolor sit amet consectetur adipisicing elit."
            }
          />
        ),
        selector: (row) => row.tvlUsd,
        sortable: true,
        format: (row) => `$ ${row.tvlUsd.toLocaleString()}`,
        minWidth: "140px",
        right: true,
      },
      {
        name: <ColTitle name="30D APY" isBold={true} />,
        selector: (row) => row.apyMean30d,
        sortable: true,
        format: (row) => (
          <div className="font-bold">
            {row.apyMean30d <= 0
              ? "-"
              : `${row.apyMean30d.toFixed(2).toLocaleString()}%`}
          </div>
        ),
        minWidth: "140px",
        right: true,
      },
      {
        name: <ColTitle name="7D APY" />,
        selector: (row) => row.apyBase7d,
        sortable: true,
        format: (row) =>
          row.apyBase7d <= 0
            ? "-"
            : `${row.apyBase7d.toFixed(2).toLocaleString()}%`,
        minWidth: "140px",
        right: true,
      },
      {
        name: <ColTitle name="1D APY" />,
        selector: (row) => row.apyBase1d,
        sortable: true,
        format: (row) =>
          row.apyBase1d <= 0
            ? "-"
            : `${row.apyBase1d.toFixed(2).toLocaleString()}%`,
        minWidth: "140px",
        right: true,
      },
      {
        name: <ColTitle name="1D Volume" />,
        selector: (row) => row.volumeUsd1d,
        sortable: true,
        format: (row) => `$ ${row.volumeUsd1d.toLocaleString()}`,
        minWidth: "140px",
        right: true,
      },
      {
        name: <ColTitle name="7D Volume" />,
        selector: (row) => row.volumeUsd7d,
        sortable: true,
        format: (row) => `$ ${row.volumeUsd7d.toLocaleString()}`,
        minWidth: "140px",
        right: true,
      },

      {
        name: <ColTitle name="Daily APR 30D" />,
        selector: (row) => row.dailyApy30d,
        sortable: true,
        format: (row) =>
          row.dailyApy30d <= 0
            ? "-"
            : `${row.dailyApy30d ? row.dailyApy30d.toFixed(4) : 0}%`,
        minWidth: "140px",
        right: true,
      },
      {
        name: <ColTitle name="Daily APR 7D" />,
        selector: (row) => row.dailyApy7d,
        sortable: true,
        format: (row) =>
          row.dailyApy7d <= 0
            ? "-"
            : `${row.dailyApy7d ? row.dailyApy7d.toFixed(4) : 0}%`,
        minWidth: "140px",
        right: true,
      },
      {
        name: <ColTitle name="Daily APR 1D" />,
        selector: (row) => row.dailyApy1d,
        sortable: true,
        format: (row) =>
          row.dailyApy1d <= 0
            ? "-"
            : `${row.dailyApy1d ? row.dailyApy1d.toFixed(4) : 0}%`,
        minWidth: "140px",
        right: true,
      },
      {
        name: <ColTitle name="vol/TVL 1D" />,
        selector: (row) => row.volumeTvl,
        sortable: true,
        format: (row) => `${row.volumeTvl.toFixed(4).toLocaleString()}`,
        minWidth: "140px",
        right: true,
      },
      {
        name: <ColTitle name="vol/TVL 7D" />,
        selector: (row) => row.volumeTvl7d,
        sortable: true,
        format: (row) => `${row.volumeTvl7d.toFixed(4).toLocaleString()}`,
        minWidth: "140px",
        right: true,
      },
      {
        name: <ColTitle name="Pool Fees" />,
        selector: (row) => row.poolMeta,
        sortable: true,
        format: (row) => row.poolMeta,
        minWidth: "140px",
        right: true,
      },
      {
        name: "Stablecoin",
        selector: (row) => row.stablecoin,
        sortable: true,
        format: (row) => `${row.stablecoin === "True" ? "Yes" : "No"}`,
        grow: 2,
      },
    ];
  }, []);

  useEffect(() => {
    if (!bestFarmsData) return;
    const filtered = bestFarmsData.filter((item) => {
      const matchesTokenName =
        item.symbol.length === 0 ||
        item.symbol.toLowerCase().includes(symbolName.toLowerCase());
      return (
        (apy1dFilter.length === 0
          ? true
          : isValueInIntervarls(
              item.apyBase1d,
              apy1dFilter,
              apyBase1dIntervals
            )) &&
        (apy30dFilter.length === 0
          ? true
          : isValueInIntervarls(
              item.apyMean30d,
              apy30dFilter,
              apyBase30dIntervals
            )) &&
        (tvlUsdFilter.length === 0
          ? true
          : isValueInIntervarls(item.tvlUsd, tvlUsdFilter, tvlUsdIntervals)) &&
        (!onlyStable ? true : item.stablecoin === "True") &&
        (selectedChain === "All Chains" || item.chain === selectedChain) &&
        (selectedProject === "All Projects" ||
          item.project === selectedProject) &&
        matchesTokenName
      );
    });
    setFilteredData(filtered);
  }, [
    bestFarmsData,
    tvlUsdFilter,
    onlyStable,
    selectedChain,
    selectedProject,
    apy1dFilter,
    apy30dFilter,
    symbolName,
  ]);
  if (loading) {
    return (
      <main className="w-full h-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
        <div className="w-full flex items-center justify-center flex-grow">
          <div className="flex items-center flex-col">
            <Loader />
            <div className="text-white/70 text-sm">Loading Data...</div>
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className="w-full flex h-fit  flex-col px-5 md:px-10 py-10 relative">
      {backendError && (
        <div className="h-screen w-full absolute">
          <BEErrorBanner />
        </div>
      )}

      <h2 className="text-[22px] text-neutral-200 font-semibold uppercase">
        Best farms
      </h2>
      <p className="text-sm text-neutral-300/70 mb-5">
        A cutting-edge DeFi yield aggregator that sifts through countless
        liquidity pools to identify the most profitable farming opportunities.
        Set your preferred parameters and discover optimal yields across
        multiple chains and protocols.
      </p>
      {highlightedFarms?.farms && (
        <HighlightedFarms data={highlightedFarms.farms} />
      )}
      <TableFilters
        setTvlUsdFilter={handleFilterTvlUsd}
        tvlUsdFilter={tvlUsdFilter}
        onlyStable={onlyStable}
        setOnlyStable={setOnlyStable}
        chains={chains}
        selectedChain={selectedChain}
        setSelectedChain={setSelectedChain}
        apy1dFilter={apy1dFilter}
        apy30dFilter={apy30dFilter}
        setApy1dFilter={handleFilterApy1d}
        setApy30dFilter={handleFilterApy30d}
        projects={projects}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        symbolName={symbolName}
        setSymbolName={setSymbolName}
      />
      <div className="w-full border border-neutral-300/20 rounded-md flex flex-col overflow-auto h-fit relative">
        <img
          src="/SVG/pic-nero.svg"
          alt="bc logo"
          className="aspect-square pointer-events-none] h-[70%] w-auto absolute left-1/2 top-1/3 md:top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10"
        />
        {filteredData && (
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            fixedHeader={true}
            theme="armyTheme"
            paginationPerPage={50}
            paginationRowsPerPageOptions={[50, 100, 250]}
          />
        )}
      </div>
      <div className="mt-2 mb-5 text-xs text-red-300/70">
        Last updated: {lastUpdated && fullDate(lastUpdated)}
      </div>
    </main>
  );
};
