import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/Home";
import Sidebar from "./components/SideBar";
import Header from "./components/Header";
import MobileMenu from "./components/MobileMenu";
import PEAnalysis from "./pages/PEAnalysis";
import BurnPage from "./pages/Burn";
import PEAnalysisToken from "./pages/PEAnalysisToken";
import BestFarms from "./pages/BestFarms";
import BestFarmHistory from "./pages/BestFarmsHistory";
import BlurLoans from "./pages/BlurLoans";
import Leaderboard from "./pages/Leaderboard";
import { ToastContainer } from "react-toastify";

const isMaintenance = false; /* Boolean(process.env.REACT_APP_IS_MAINTENANCE) || false; */

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/pe-analysis",
    element: <PEAnalysis />,
  },
  {
    path: "/pe-analysis/:tokenId",
    element: <PEAnalysisToken />,
  },
  {
    path: "/burn-dashboards",
    element: <BurnPage />,
  },
  {
    path: "/best-farms",
    element: <BestFarms />,
  },
  {
    path: "/best-farms/:farmId",
    element: <BestFarmHistory />,
  },
  {
    path: "/leaderboard",
    element: <Leaderboard />,
  },
  {
    path: "/blur-loans",
    element: <BlurLoans />,
  },
]);
function App() {
  if (isMaintenance)
    return (
      <div className="w-full md:h-screen md:flex md:flex-col relative items-center justify-center">
        <img
          src="/SVG/pic-nero.svg"
          alt="bc logo"
          className="pointer-events-none h-[90%] w-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-30 "
        />
        <div className="text-4xl ">Ongoing mantenance.</div>
        <div className="text-3xl text-red-700"> We'll be back online soon.</div>
      </div>
    );
  return (
    <div className="w-full md:h-screen md:flex md:flex-row relative">
      <Sidebar />
      <Header />
      <MobileMenu />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="flex flex-grow bg-neutral-900 overflow-y-auto">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
