import Navigation from "./Navigation";
import { SocialButtons } from "./SocialButtons";

// when merge into main, increment the second number, if a small update came, update the third number
const version = '1.1.3'
const Sidebar = () => {
  return (
    <div className="hidden md:flex flex-col justify-between h-screen w-80 flex-shrink-0 bg-ui-dark-gray pb-5 pt-24">
      <Navigation />
      <div className="w-full">
        <SocialButtons />
      </div>
      <div className="w-full pl-5 pr-5 text-xs text-red-500">v {version}</div>
    </div>
  );
};
export default Sidebar;
